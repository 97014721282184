<template>
    <main class="flex-grow-1 align-content-center">
        <div class="container h-100">
            <div style=" width: 0;" class="d-none d-lg-block mt-5">
                <h1 class="one-word-per-line">Pricing</h1>
            </div>
            <h1 class="d-block d-lg-none mt-4">Pricing</h1>


            <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                <p class="lead">We've tried to make our pricing as simple as possible.</p>
            </div>

            <div class="container">
                <div class="d-flex justify-content-md-center justify-content-start flex-column flex-lg-row mb-3 text-center px-5">
                    <div class="card mx-4 mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Free</h4>
                        </div>
                        <div class="card-body px-4">
                            <h1 class="card-title pricing-card-title">£0 <small class="text-muted">/ mo</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>30 Orders per month</li>
                            </ul>

                            <router-link
                                to="/settings"
                                v-slot="{ href, navigate, isActive }"
                                custom
                            >
                                <button @click="navigate" type="button" class="btn btn-lg btn-block btn-outline-primary my-auto">Sign in for free</button>
                            </router-link>
                        </div>
                    </div>
                    <div class="card mx-4 mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Paid</h4>
                        </div>
                        <div class="card-body px-4">
                            <h1 class="card-title pricing-card-title">£10 <small class="text-muted">/ mo</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                            <li>150 Orders per month</li>
                            </ul>

                            <router-link
                                to="/settings"
                                v-slot="{ href, navigate, isActive }"
                                custom
                            >
                                <button @click="navigate" type="button" class="btn btn-lg btn-block btn-primary my-auto">Get started</button>
                            </router-link>
                        </div>
                    </div>
                    <div class="card mx-4 mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Unlimited</h4>
                        </div>
                        <div class="card-body px-4">
                            <h1 class="card-title pricing-card-title">- <small class="text-muted">/ mo</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>Unrestricted</li>
                            </ul>

                            <router-link
                                to="/support"
                                v-slot="{ href, navigate, isActive }"
                                custom
                            >
                                <button @click="navigate" type="button" class="btn btn-lg btn-block btn-primary">Contact us</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mb-5">
                <h3>Additional services</h3>
                <div>We offer custom template design service that integrates with our system this runs for £300-600 with a turnaround time of 5-10 working days. You must have at least one picture of how you would expect this to look. A discount is applied if you allow us to let other customers use the finished design. You will be given the full source code of your design upon payment, additional later revisions are charged extra. For more information please email us at hello@.com.</div>
            </div>
        </div>
    </main>
</template>

<script>
export default {

}
</script>

<style>

</style>